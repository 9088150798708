.cc-selector input{
  margin:0;padding:0;
  -webkit-appearance:none;
    -moz-appearance:none;
      appearance:none;
}

.cc-selector-2 input{
  position:absolute;
  z-index:999;
}

.visa{background-image:url(http://i.imgur.com/lXzJ1eB.png);}
.mastercard{background-image:url(http://i.imgur.com/SJbRQF7.png);}

.cc-selector-2 input:active +.drinkcard-cc, .cc-selector input:active +.drinkcard-cc{opacity: .9;}
.cc-selector-2 input:checked +.drinkcard-cc, .cc-selector input:checked +.drinkcard-cc{
  -webkit-filter: none;
    -moz-filter: none;
      filter: none;
}
.drinkcard-cc{
  cursor:pointer;
  background-size: contain;
  background-position: center;
  background-repeat:no-repeat;
  display:inline-block;
  width:80px;height:50px;
  -webkit-transition: all 100ms ease-in;
    -moz-transition: all 100ms ease-in;
      transition: all 100ms ease-in;
  // -webkit-filter: brightness(1.8) grayscale(1) opacity(.7);
  //  -moz-filter: brightness(1.8) grayscale(1) opacity(.7);
  // filter: brightness(1.8) grayscale(1) opacity(.7);
}
.drinkcard-cc:hover{
  -webkit-filter: brightness(1.2) grayscale(.5) opacity(.9);
    -moz-filter: brightness(1.2) grayscale(.5) opacity(.9);
      filter: brightness(1.2) grayscale(.5) opacity(.9);
}
