

.Toastify__toast--success {
  /*color: rgb(30, 70, 32);*/
  background-color: rgb(237, 247, 237) !important;
}

.Toastify__toast {
  border-radius: 10px;
}

.Toastify__toast--info {
  color: rgb(13, 60, 97);
  background: rgb(232, 244, 253);
}

.Toastify__toast--warning {
  color: rgb(102, 60, 0);
  background-color: #fff4e5 !important;
}

.Toastify__toast--error {
  background-color: rgb(253, 236, 234) !important;
  color: rgb(97, 26, 21);
}

.Toastify__close-button {
  fill: lightgray;
  color: lightgray;
  padding-right: 1px;
  padding-top: 1px;
}