
.rug .rug-handle:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 8px);
  height: calc(100% - 8px);
  border-radius: 4px;
  border: 1px solid lightgray!important;
}

.rug .rug-handle .rug-handle-info .rug-handle-button {
  background-color: white!important;
  border-radius: 3px;
  padding: 7px 12px;
  font-size: 16px;
  color: #f5f5f5;
  text-align: center;
  cursor: pointer;
  max-width: 250px;
  display: block;
  margin: 0 auto;
}

.rug-handle-icon {
  display: none;
}
.rug .rug-handle .rug-handle-info .rug-handle-drop-text {
font-size: 16px!important;
}

.rug-card .rug-card-upload-button {
  display: none!important;
;
}
.rug .rug-items.__card .rug-item {
  justify-content: start!important;
}
/* .rug-card::after {
  content: ".file";
  color: #cfcfcf;
  font-size: 1.5rem;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
} */

.rug .rug-items.__card {
  display: grid;
  grid-gap: 0;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  margin: 0 -10px;
  min-height: 0!important;
}